import React from "react";
import "./App.scss";
import { AppBar } from "../component/menu/AppBar";
import { HomePage } from "../pages/home/HomePage";
import Container from "@material-ui/core/Container";
import { Footer } from "../component/footer/Footer";

function App() {
  return (
    <main>
      <section className="app-header">
        <AppBar />
      </section>
      <section className="app-body">
        <Container maxWidth="md">
          <HomePage />
        </Container>
      </section>
      <section className="app-footer">
        <Footer />
      </section>
    </main>
  );
}

export default App;
