import React, { useState } from "react";
import MuiAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { MenuMobile } from "./MenuMobile";
import { Menu } from "./Menu";
import "./AppBar.scss";
import { Drawer } from "@material-ui/core";
import { DrawerPanel } from "./drawer/DrawerPanel";

export const menuId = "app-search-account-menu-bar";
export const mobileMenuId = menuId.concat("mobile");

interface AppBarProps {
  user?: Object;
}

export const AppBar: React.FC<AppBarProps> = ({ user }) => {
  const [notificationState] = useState<number>(0);
  const [anchorElState, setAnchorElState] = React.useState<null | HTMLElement>(
    null
  );
  const [mobileMoreAnchorElState] = React.useState<null | HTMLElement>(null);
  const [openDrawerState, setOpenDrawerState] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpenDrawerState(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawerState(false);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElState(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorElState(null);
  };

  return (
    <div className="app-bar grow">
      <MuiAppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={"MenuButton"}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="body1" noWrap className={"title"}>
            Sorare Blueprint
          </Typography>
          <div className={"grow"} />
          <div className={"sectionDesktop"}>
            {user && (
              <IconButton
                aria-label={`show ${notificationState} new notifications`}
                color="inherit"
              >
                <Badge badgeContent={notificationState} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            )}
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div>
        </Toolbar>
      </MuiAppBar>
      <Menu
        anchorEl={anchorElState}
        handleMenuClose={handleMenuClose}
        user={user}
      />
      <MenuMobile
        mobileMoreAnchorEl={mobileMoreAnchorElState}
        handleProfileMenuOpen={handleProfileMenuOpen}
        handleMobileMenuClose={handleMenuClose}
      />
      <Drawer open={openDrawerState} onClose={handleDrawerClose}>
        <DrawerPanel handleDrawerClose={handleDrawerClose} />
      </Drawer>
    </div>
  );
};
