import React from "react";
import TwitterIcon from "@material-ui/icons/Twitter";
import Link from "@material-ui/core/Link";
import "./Footer.scss";

export const Footer: React.FC = () => {
  return (
    <footer className="AppFooter topSpacing">
      <div className="FooterCredits">
        <Link href="https://twitter.com/sorareblueprint" target="_blank">
          <TwitterIcon color="primary" />
        </Link>
      </div>
      <div className="FooterBottom">© 2021 Sorare Blueprint</div>
    </footer>
  );
};
