import React from "react";
import {
  Divider,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import TwitterIcon from "@material-ui/icons/Twitter";
import "./DrawerPanel.scss";

interface DrawerPanelProps {
  handleDrawerClose: () => void;
}

export const DrawerPanel: React.FC<DrawerPanelProps> = ({
  handleDrawerClose,
}) => {
  return (
    <div
      role="presentation"
      onClick={handleDrawerClose}
      onKeyDown={handleDrawerClose}
      className="drawerPanel"
    >
      <List>
        <ListItem>
          <ListItemIcon></ListItemIcon>
          <ListItemText primary={""} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem>
          <Link
            href="https://twitter.com/SorareBlueprint"
            noWrap
            target="_blank"
            className={"listItem"}
          >
            <ListItemIcon>
              <TwitterIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="@SorareBlueprint" color="primary" />
          </Link>
        </ListItem>
      </List>
    </div>
  );
};
