import React from "react";
import { Grid, Link, ListItemIcon, Typography } from "@material-ui/core";
import TwitterIcon from "@material-ui/icons/Twitter";
import "./HomePage.scss";
import SorareBlueprintLogo from "../../../img/sorareBlueprintLogo-200.png";
import SorareBlueprintClay from "../../../img/sorareBlueprintClay.png";

export const HomePage = () => {
  return (
    <div id="homePage">
      <Grid container>
        <Grid item xs={12} sm={12} md={3}>
          <img
            src={SorareBlueprintLogo}
            alt="Sorare Blueprint logo"
            width={200}
            height={"auto"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={9} className="presentation">
          <Typography variant="h1" className={"title"}>
            Sorare Blueprint
          </Typography>
          <Typography variant="body1">
            The tool for wining managers at playing SO5 and trading Sorare NFT
            collectible cards. We help managers with decision making for the
            good sake of their gallery.
          </Typography>
        </Grid>
      </Grid>
      <Grid container className="secondBloc">
        <Grid item xs={12} sm={12} md={6} className="topSpacing">
          <img
            src={SorareBlueprintClay}
            alt="clay draw about a football pitch"
            width={300}
            height={"auto"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} className="topSpacing">
          <div className="grow" />
          <div className="right">
            <Typography variant="body1">We will launch soon!</Typography>
            <Typography variant="body1">
              Keep in touch join us on twitter
            </Typography>
            <Link href="https://twitter.com/sorareblueprint" target="_blank">
              <ListItemIcon>
                <TwitterIcon color="primary" className="icon" />
              </ListItemIcon>
            </Link>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
