import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import MuiMenu from "@material-ui/core/Menu";
import { menuId } from "./AppBar";

interface MenuProps {
  user?: Object;
  anchorEl: null | HTMLElement;
  handleMenuClose: () => void;
}
export const Menu: React.FC<MenuProps> = ({
  user,
  anchorEl,
  handleMenuClose,
}) => {
  const isMenuOpen = Boolean(anchorEl);
  return (
    <MuiMenu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {user && (
        <>
          <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
          <MenuItem onClick={handleMenuClose}>My account</MenuItem>
        </>
      )}
      {!user && <MenuItem onClick={handleMenuClose}>Login</MenuItem>}
    </MuiMenu>
  );
};
